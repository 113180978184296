import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import '../styles/global.scss'
import { Header } from "../components/Header/Header";
import NewBlogs from "../components/NewBlogs/NewBlogs";
import { Bio } from "../components/Bio/Bio";

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;
  const postsInIndexPage = data.site.siteMetadata.postsInIndexPage;
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <Header />
      <NewBlogs posts={posts} postsInIndexPage={postsInIndexPage}/>
      <Bio />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
      postsInIndexPage
    }
  }
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          cover {
            publicURL
          }
        }
      }
    }
  }
}
`
