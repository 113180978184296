import React, { useEffect, useState } from 'react';
import ContainerBio from './Bio.module.scss';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

export const Bio = () => {
    const data = useStaticQuery(graphql`
        query {
            gammafp: file(absolutePath: { regex: "/gammafp.png/"}) {
                childImageSharp {
                    fixed(width: 200, height: 200) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            gammix_oran: file(absolutePath: { regex: "/gammix_oran.png/"}) {
                childImageSharp {
                    fixed(width: 200, height: 200) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    const { t } = useTranslation();
    const [translate, set_translate] = useState(t('how_i_am', { returnObjects: true}));
    useEffect(() => {
        set_translate(t('how_i_am', { returnObjects: true}));
    }, [t]);

    return (
        <div className={`${ContainerBio.container} mt-3`}>
            <div className="container-md text-white py-2">
                <div className="row d-flex justify-content-center">
                    <div className="col-8">
                        <h5 className="text-center my-4">{t('how_i_am', {returnObjects: true}).title}</h5>
                        <div className="text-center text-md-left">
                            <Image fixed={data.gammafp.childImageSharp.fixed} className="float-md-left" />
                            <p className="content-heading text-left" dangerouslySetInnerHTML={{ __html:translate.description}} />
                        </div>
                        <hr className="border-white" />
                        <h5 className="text-center">{t('patreon', {returnObjects: true}).title}</h5>
                        <div className="text-center text-md-left">
                            <Image fixed={data.gammix_oran.childImageSharp.fixed} className="float-md-right" style={{transform: 'scale(-1, 1)'}} />
                            <p className="text-left" dangerouslySetInnerHTML={{ __html:t('patreon', {returnObjects: true}).description}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
