// Componente para mostrar un número de posts determinado
import React, { useEffect } from 'react';
import Card from '../Card/Card';
import { useTranslation } from 'react-i18next';
import { Observer, GLOBAL_EVENT } from '../../Observer';

const NewBlogs = ({ posts, postsInIndexPage }) => {
  const { i18n } = useTranslation();
  const posts_lang = posts.filter((edge, i) => {
    return (edge.node.fields.slug.startsWith(`/${i18n.language}/`));
  });

  useEffect(() => {
    Observer.emit(GLOBAL_EVENT.SET_LANG);
  });

  return (
    <>
    <h3 className="text-primary-color text-center mt-3">
      Last Posts
    </h3>
    <div className="card-deck justify-content-center text-center mt-3 mr-0">
      {
        posts_lang.map((post, i) => {
          const node = post.node;
          const excerpt = (node.frontmatter.description !== null) ? node.frontmatter.description : node.excerpt;
          if (node.frontmatter.tags !== null && i < postsInIndexPage) {
            const tag = node.frontmatter.tags[0];
            const cover = (node.frontmatter.cover) ? node.frontmatter.cover.publicURL : null;
            return <Card className="mx-0 mx-sm-2 my-1 my-sm-3" key={i}
                slug={node.fields.slug}
                title={node.frontmatter.title}
                excerpt={excerpt}
                tag={tag}
                cover={cover}
              />;
          } else {
            return null;
          }
        })
      }
    </div>
    </>
  )
}

export default NewBlogs;
